import * as React from "react";
import { motion } from "framer-motion";

const FigmaSvg = (props) => (
  <motion.svg
    initial={{ rotate: 0 }}
    animate={{ rotate: 360 }}
    transition={{
      duration: 0.3,
      delay: 0.1,
    }}
    width={54}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.717 0C6.56 0 0 6.843 0 15.227c0 5.096 2.427 9.623 6.157 12.386A15.377 15.377 0 0 0 0 40c0 5.1 2.427 9.623 6.157 12.387A15.377 15.377 0 0 0 0 64.773C0 73.157 6.56 80 14.717 80c8.153 0 14.716-6.843 14.716-15.227V51.9a14.357 14.357 0 0 0 9.184 3.33c8.157-.003 14.717-6.847 14.717-15.23a15.365 15.365 0 0 0-6.157-12.387 15.377 15.377 0 0 0 6.157-12.386C53.334 6.843 46.773 0 38.617 0h-23.9ZM5.53 15.227c0-5.304 4.14-9.55 9.187-9.55H23.9v19.1h-9.183c-5.047 0-9.187-4.25-9.187-9.55Zm33.087 9.546h-9.183v-19.1h9.183c5.05 0 9.187 4.25 9.187 9.554 0 5.3-4.137 9.546-9.187 9.546ZM5.534 40c0-5.3 4.14-9.55 9.186-9.55h9.184v19.1h-9.187c-5.05 0-9.187-4.25-9.187-9.55h.004Zm23.9 0c0-5.3 4.14-9.55 9.186-9.55 5.05 0 9.187 4.247 9.187 9.55 0 5.3-4.137 9.55-9.187 9.55-5.046 0-9.183-4.247-9.183-9.55h-.004Zm-23.9 24.773c0-5.3 4.14-9.546 9.186-9.546h9.184v9.546c0 5.304-4.134 9.55-9.184 9.55-5.046 0-9.186-4.246-9.186-9.55Z"
      fill="#8EE4AF"
    />
  </motion.svg>
);

export default FigmaSvg;
