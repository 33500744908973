import "./App.css";
import { About, Contact, Home, Projects } from "./pages";
import { Route, Routes, useLocation } from "react-router-dom";
import { Logo, Navigation } from "./components";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";
import bg from "./assets/background.svg"

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <BackgroundLayer />
      <Logo></Logo>
      <Navigation></Navigation>
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/projects" exact element={<Projects />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;

const BackgroundLayer = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url(${bg});
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
`;
