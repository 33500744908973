import * as React from "react";
import { motion } from "framer-motion";

const TsSvg = (props) => (
  <motion.svg
    initial={{ rotate: 0 }}
    animate={{ rotate: 360 }}
    transition={{
      duration: 0.3,
      delay: 0.9,
    }}
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 40v40h80V0H0v40Zm64.465-3.19a10.058 10.058 0 0 1 5.013 2.89c.73.78 1.822 2.212 1.915 2.565.025.105-3.45 2.435-5.56 3.737-.078.053-.39-.272-.73-.782-1.028-1.498-2.11-2.148-3.763-2.265-2.423-.157-3.985 1.108-3.973 3.23a3.15 3.15 0 0 0 .338 1.497c.535 1.108 1.538 1.77 4.635 3.113 5.73 2.46 8.177 4.087 9.7 6.392 1.705 2.578 2.082 6.693.938 9.766-1.275 3.32-4.428 5.584-8.855 6.327-1.368.248-4.623.207-6.096-.065-3.215-.573-6.262-2.163-8.137-4.245-.742-.808-2.175-2.93-2.083-3.073.04-.052.365-.26.73-.47l2.97-1.72 2.306-1.34.482.716c.677 1.027 2.148 2.434 3.035 2.902 2.553 1.355 6.055 1.16 7.788-.39A2.921 2.921 0 0 0 66.16 63.2c0-.925-.117-1.34-.6-2.033-.625-.885-1.888-1.64-5.495-3.202-4.127-1.785-5.912-2.878-7.525-4.635a10.59 10.59 0 0 1-2.2-3.997c-.3-1.133-.378-3.973-.143-5.105.848-3.998 3.868-6.77 8.203-7.59 1.407-.273 4.688-.17 6.068.17l-.003.002ZM45.69 40.157l.025 3.268H35.297v29.597h-7.37V43.425H17.526v-3.203c0-1.785.04-3.267.09-3.307.04-.053 6.368-.078 14.05-.065l13.985.04.04 3.267Z"
      fill="#8EE4AF"
    />
  </motion.svg>
);

export default TsSvg;
