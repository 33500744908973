import * as React from "react";
import { motion } from "framer-motion";

const ScssSvg = (props) => (
  <motion.svg
    initial={{ rotate: 0 }}
    animate={{ rotate: 360 }}
    transition={{
      duration: 0.3,
      delay: 0.7,
    }}
    width={76}
    height={76}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38 0c20.993 0 38 17.007 38 38S58.993 76 38 76 0 58.993 0 38 17.007 0 38 0Zm-7.56 50.654c.558 2.054.497 3.96-.073 5.679l-.2.57c-.073.197-.17.382-.247.558a10.76 10.76 0 0 1-1.755 2.572c-2.216 2.401-5.294 3.315-6.619 2.548-1.434-.829-.72-4.23 1.843-6.942 2.757-2.907 6.716-4.776 6.716-4.776v-.01l.335-.199Zm31.396-34.388c-1.72-6.754-12.913-8.968-23.503-5.208-6.308 2.24-13.122 5.755-18.034 10.343-5.84 5.453-6.766 10.203-6.381 12.182 1.358 7.001 10.946 11.592 14.893 14.981v.026c-1.166.568-9.673 4.836-11.666 9.265-2.14 4.648.335 7.978 1.94 8.398 4.988 1.385 10.12-1.14 12.88-5.223 2.66-3.992 2.423-9.113 1.273-11.637 1.57-.42 3.427-.62 5.802-.335 6.657.767 7.98 4.95 7.695 6.657-.285 1.708-1.66 2.708-2.14 2.993-.468.285-.617.37-.57.57.05.285.285.285.668.235.52-.086 3.465-1.423 3.612-4.653.136-4.094-3.762-8.645-10.687-8.56-2.858.065-4.665.297-5.938.818a1.446 1.446 0 0 0-.335-.335c-4.277-4.6-12.207-7.828-11.875-13.965.102-2.226.905-8.113 15.203-15.238 11.725-5.852 21.09-4.23 22.697-.668 2.328 5.083-4.985 14.535-17.192 15.906-4.653.52-7.078-1.273-7.695-1.94-.667-.72-.753-.768-1.005-.618-.37.183-.135.805 0 1.187.383.95 1.855 2.61 4.43 3.46 2.225.72 7.695 1.14 14.25-1.422 7.36-2.843 13.1-10.773 11.44-17.43l.238.211Z"
      fill="#8EE4AF"
    />
  </motion.svg>
);

export default ScssSvg;
