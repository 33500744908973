import * as React from "react";
import { motion } from "framer-motion";

const JsSvg = (props) => (
  <motion.svg
    initial={{ rotate: 0 }}
    animate={{ rotate: 360 }}
    transition={{
      duration: 0.3,
      delay: 0.3,
    }}
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0v80h80V0H0Zm43.537 62.391c0 7.786-4.571 11.34-11.231 11.34-6.017 0-9.5-3.105-11.286-6.874l6.126-3.697c1.18 2.089 2.251 3.857 4.84 3.857 2.463 0 4.034-.966 4.034-4.731V36.73h7.517v25.66Zm17.783 11.34c-6.983 0-11.5-3.32-13.697-7.68l6.126-3.534c1.608 2.626 3.714 4.572 7.411 4.572 3.106 0 5.109-1.555 5.109-3.715 0-2.571-2.038-3.483-5.483-5l-1.875-.803c-5.428-2.302-9.017-5.214-9.017-11.34 0-5.642 4.303-9.928 11-9.928 4.786 0 8.215 1.66 10.68 6.017l-5.857 3.751c-1.286-2.302-2.68-3.214-4.84-3.214-2.194 0-3.588 1.394-3.588 3.214 0 2.252 1.394 3.16 4.625 4.572l1.875.803c6.394 2.731 9.982 5.537 9.982 11.82 0 6.751-5.322 10.463-12.448 10.463l-.003.002Z"
      fill="#8EE4AF"
    />
  </motion.svg>
);

export default JsSvg;
