const Eye = (props) => (
  <svg
    width={47}
    height={47}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.742 23.794c-.074.147-1.58 3.47-4.939 6.83-3.36 3.36-8.464 6.83-16.303 6.83-7.84 0-13.2-3.71-16.303-6.83-3.103-3.122-4.865-6.683-4.939-6.83a.808.808 0 0 1 0-.588c.074-.147 1.58-3.47 4.939-6.83 3.36-3.36 8.463-6.83 16.303-6.83 7.84 0 13.2 3.71 16.303 6.83 3.103 3.122 4.865 6.683 4.939 6.83a.808.808 0 0 1 0 .588Zm-6.004-6.426c-4.24-4.204-9.363-6.352-15.238-6.352s-10.997 2.148-15.238 6.352A25.462 25.462 0 0 0 3.745 23.5a25.115 25.115 0 0 0 4.517 6.132c4.24 4.223 9.363 6.352 15.238 6.352s10.997-2.13 15.238-6.352a25.115 25.115 0 0 0 4.517-6.132 25.115 25.115 0 0 0-4.517-6.132ZM23.5 31.578a8.078 8.078 0 1 1 0-16.156 8.078 8.078 0 0 1 0 16.156Zm0-14.687a6.61 6.61 0 1 0 0 13.22 6.61 6.61 0 0 0 0-13.22Z"
      // fill="#8EE4AF"
    />
  </svg>
);

export default Eye;
