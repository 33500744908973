import * as React from "react";
import { motion } from "framer-motion";

const StyledCompSvg = (props) => (
  <motion.svg
    initial={{ rotate: 0 }}
    animate={{ rotate: 360 }}
    transition={{
      duration: 0.3,
      delay: 0.8,
    }}
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.061 38.413c-.312 3.935 3.145 6.833 5.503 6.833 2.363-.11 11.563-7.864 11.563-7.864 4.328-5.472 4.346-7.906 4.346-9.818-.36-8.445-15.745 4.417-21.412 10.85Zm38.057-3.268c2.089-4.42-.721-9.329-6.832-5.017-4.857 3.086-11.144 9.436-14.152 11.922-1.396 2.819 2.142 10.555 7.748 6.47 6.627-4.814 11.466-9.341 13.236-13.375Zm5.688-26.737L65.152 0H80v15.35l-5.442.064-6.552 22.193c.408 7.832-3.564 7.96-4.072 11.554-1.275 9.033-11.376 16.424-15.238 16.424-2.576-.075-4.459-3.027-5.091-5.6 7.262-8.033 17.028-14.345 17.028-14.345.61-4.225-.73-5.57 3.777-9.17l4.302-22.615c-3.674-2.426-5.388-2.388-4.906-5.447ZM34.823 45.862c3.766 7.588 9.208 7.785 17.644-1.875.2.893.42 1.829.017 4.075C35.322 63.413 19.158 78.352 14.238 80H0v-3.819c2.316-3.211 21.32-18.886 34.823-30.32Zm25.364 14.692c-.773 6.568-6.76 12.315-14.763 19.446h-25.36l21.38-18.009c3.69 8.4 10.246 7.183 18.743-1.437Zm-29.99-14.909L0 71.383V54.685l17.77-14.466c.95 5.384 6.774 10.126 12.427 5.427Z"
      fill="#8EE4AF"
    />
  </motion.svg>
);

export default StyledCompSvg;
