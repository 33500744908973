import * as React from "react";
import { motion } from "framer-motion";

const NodeJs = (props) => (
  <motion.svg
    initial={{ rotate: 0 }}
    animate={{ rotate: 360 }}
    transition={{
      duration: 0.3,
      delay: 0.4,
    }}
    width={74}
    height={76}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M37 76c-1.15 0-2.22-.282-3.219-.77l-10.188-5.514c-1.53-.768-.764-1.047-.303-1.187 2.065-.628 2.448-.768 4.595-1.888.228-.14.538-.07.766.07l7.814 4.26c.303.14.69.14.918 0L67.95 54.844c.302-.14.457-.418.457-.767V21.889c0-.35-.151-.628-.457-.768L37.383 5.066c-.306-.14-.69-.14-.918 0L5.9 21.12c-.306.14-.461.489-.461.768v32.187c0 .279.155.628.46.767l8.35 4.4c4.52 2.094 7.352-.348 7.352-2.791V24.684c0-.419.384-.838.922-.838h3.905c.46 0 .918.35.918.838v31.765c0 5.516-3.293 8.727-9.038 8.727-1.762 0-3.141 0-7.046-1.745l-8.043-4.188a6.066 6.066 0 0 1-2.344-2.136A5.61 5.61 0 0 1 0 54.145V21.96c0-2.094 1.224-4.048 3.219-5.095L33.784.732A7.393 7.393 0 0 1 37 0c1.118 0 2.22.251 3.216.732l30.568 16.129c1.992 1.046 3.216 3 3.216 5.095v32.186c0 2.094-1.224 4.048-3.216 5.098L40.216 75.365a8.491 8.491 0 0 1-3.216.629V76Zm9.422-22.134c-13.404 0-16.163-5.583-16.163-10.332 0-.419.384-.838.918-.838h3.983c.46 0 .841.28.841.698.616 3.7 2.378 5.514 10.498 5.514 6.434 0 9.193-1.326 9.193-4.467 0-1.814-.767-3.14-10.8-4.048-8.353-.77-13.56-2.442-13.56-8.518 0-5.656 5.207-9.009 13.943-9.009 9.805 0 14.631 3.074 15.244 9.777a1.062 1.062 0 0 1-.233.628c-.15.14-.38.28-.612.28h-3.982a.925.925 0 0 1-.532-.182.838.838 0 0 1-.31-.447c-.92-3.838-3.296-5.098-9.575-5.098-7.05 0-7.892 2.234-7.892 3.909 0 2.027.996 2.655 10.495 3.772 9.422 1.116 13.865 2.722 13.865 8.724-.078 6.148-5.59 9.637-15.322 9.637Z"
      fill="#8EE4AF"
    />
  </motion.svg>
);

export default NodeJs;
